<template>
  <div class="user">
    <div class="photo">
      <img src="@/assets/images/default-avatar.png" alt="user avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span v-if="user">
           {{ user.name }}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="/my-profile">
                <span class="sidebar-mini-icon">Mp</span>
                <span class="sidebar-normal">My Profile</span>
              </a>
            </li>
            <li>
                <a href="/company/user-location">
                  <span class="sidebar-mini-icon">BA</span>
                  <span class="sidebar-normal">Billing Address</span>
                </a>
              </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import { mapState } from 'vuex';

  export default {
    components: {
      CollapseTransition
    },
    data() {
        return {
          isClosed: true
        }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      }
    },
    computed: mapState(['user']),
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
