<template>
  <div :class="{'nav-open': $sidebar.showSidebar}" id="app">
   
    <notifications transition-name="notification-list" transition-mode="out-in">
      
    </notifications>
    <router-view name="header"></router-view>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  // Loading some plugin css asynchronously
  import 'sweetalert2/dist/sweetalert2.css'
import 'vue-notifyjs/themes/default.css'

export default {
  data() {
    return {
      config: process.env.VUE_APP_TEST
    };
  },
  created: function () {}
  }
</script>

<style lang="scss">
  .vue-notifyjs .alert {
    z-index: 99999 !important;
  }
  .red-label{
    color: #dc3545 !important;
  }
</style>
