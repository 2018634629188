<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">

        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="">
        <span>{{ user ? user.name : '' }}</span>
        <div class="nav-plan" v-if="user && user.company && user.company.plan">({{ user.company.plan.name}})</div>
       
         <!-- {{ getPlan() ? "(" + this.getPlan().name + ")" : '' }} -->
      </a>
      
      <!-- <a href="#/select-company" v-if="user && user.company">Change</a> -->
    </div>

    <template slot="navbar-menu">
      <ul class="navbar-nav">
        <notification></notification>
        <li class="nav-item">
          <a class="nav-link btn-rotate" href="/logout">
            <i class="fa fa-power-off" title="Logout"></i>
            <p>
              <span class="d-lg-none d-md-block">Logout</span>
            </p>
          </a>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import { mapState } from 'vuex';
import Notification from './Notification.vue';
  
  export default {
    components: {
      Navbar,
      NavbarToggleButton,
        Notification,
    },
    computed: {
      ...mapState(['user'])
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false,
      }
  },
  methods: {
      hasCompany() {
          return this.user && this.user.company  
      },
      getPlan() {
        return this.hasCompany() ? this.user.company.plan : null
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      }
    }
  }

</script>
<style>

</style>
