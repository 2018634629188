import api from './api'

const API = '/api/companies'

export const Company = {
  get: function(params) {
    return api.get(API, params)
  },
  userCompanies: function(params) {
    return api.get(API+'/user_companies', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  uploadForm: function(form) {
    return api.createFormData(API+'/upload-mail-form', form)
  },
  updateMailForm: function (id, form) {
        return api.update(API+'/'+id+'/update-mail-form', form)
  },
  saveCompanyProfile: function(params) {
    return api.create(API+'/company-profile', params)
  },
  setUserCompany: function(id, params) {
    return api.update(API+'/'+id+'/set_user_company', params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
