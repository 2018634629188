export const LocalStorageService = {
    getLoadingUser: function() {
      let status = localStorage.getItem('loading_user')
      if (status == 'true') status = true
      if (status == 'false') status = false
      return status != null  ? status : true;
    },
    setLoadingUser: function(status) {
      localStorage.setItem('loading_user', status);
    },
    getToken: function() {
      return localStorage.getItem('token');
    },
    setToken: function(token) {
      localStorage.setItem('token', token);
    },
    setSupplier: function(supplier) {
      var user = this.getUser()
      if (user) {
        if (user.company) {
          user.company.supplier = supplier
          this.setUser(user);
        }
      }
    },
    setCompany: function(company) {
      var user = localStorage.getItem('user')
      if (user) {
        user = JSON.parse(user);
        user.company = company
        this.setUser(user);
      }
    },
    getUser: function() {
      var user = localStorage.getItem('user')
      if (user) user = JSON.parse(user);
      return user;
    },
    getCompanyId: function() {
      var user = localStorage.getItem('user')
      if (user == 'null') return null;
      if (user) {
        user = JSON.parse(user);
        if (user.company) return user.company.id
      }
      
      return null;
    },
    setUser: function(user) {
      localStorage.setItem('user', user ? JSON.stringify(user) : null);
    }
  }
  