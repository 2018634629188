<template>
  <div class="wrapper" v-if="user && user.app_name == 'biz_depot'" id="google-translate">

    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks" style="margin-top: 40px">
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>
      
      <dashboard-content @click.native="toggleSidebar" style="margin-top: 120px">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"> 

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import { mapState } from 'vuex';

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
    UserMenu
  },
  data() {
    return {
      
    }
  },
  created: function () {
    if (!this.user || (this.user && this.user.app_name != 'biz_depot')) {
        if (this.user) this.$store.commit('saveUser', null);
        this.$router.push({ name: 'Login' })
      }
    },
  methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    computed: mapState(['user']),
  }

</script>
