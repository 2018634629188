<template>
  
  
  <drop-down icon="nc-icon nc-bell-55" tag="li"
                position="right"
                direction="none"
                class="nav-item btn-rotate dropdown">
      <a slot="title"
          slot-scope="{isOpen}"
          class="nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          :aria-expanded="isOpen"
        
          @click="openNotification()"
          >
          <el-badge :value="unreadMessagesCount" class="item" :hidden="!unreadMessagesCount">
             <i class="nc-icon nc-bell-55"></i>
          </el-badge>
      </a>
      <a class="dropdown-item" style="width: 350px;    overflow: hidden;" v-for="notification in notifications" :key="notification.id" @click="goTo(notification)" :class="notification.seen ? '':'unseen'">
        <div class="title">{{ notification.title }}</div>
        <div class="body" style="font-size: 11px;word-wrap: break-word;white-space: pre-line;">{{ notification.body }}</div>
        <div style="font-style:italic;font-size:10px">{{ getTime(notification.timestamp) }}</div>
      </a>

      <a class="dropdown-item" href="#" v-if="notifications.length">
        <el-button type="text" @click="clearNotifications()">Clear all</el-button>
      </a>

      <a class="dropdown-item" href="#" v-if="!notifications.length">
          <div class="title">No notifications at the moment</div>
        </a>
    </drop-down>
</template>
<script>
import { mapState } from 'vuex';
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import { CONFIG } from '@/../firebase_config'
import { User } from '@/resources/user'
import { db } from '@/firebase'

export default {
  computed: {
    ...mapState(['user']),
    unreadMessagesCount() {
      var count = 0
      this.notifications.forEach(function (notification) {
        if (!notification.seen) count++
      })
      this.unreadNotifications = count 
      return count
    }
  },
  data() {
    return {
      unreadNotifications: 0,
      notifications: [],
      doneInitFirebase: false
    }
  },
  created: function () {
    if (!this.doneInitFirebase) this.initialiazeFirebase()
    this.getNotifications()
  },
  methods: {
    goTo(notification) {
      let ref = db.ref('notifications/' + this.user.id + '/' + notification.id)
      notification.seen = true
      if (!notification.link) notification.link = ''
      ref.set(notification)


      if (notification.link && notification.link !== this.$route.path) {
        this.$router.push(notification.link)
      }

    },
    openNotification() {
      this.unreadNotifications = 0
    },
    initialiazeFirebase() {
      // var firebaseConfig = CONFIG
      let self = this
      // firebase.initializeApp(firebaseConfig)
      const messaging = firebase.messaging()
      let firebaseKeyPair = 'BJJGYANYS_vN_w5sb_fqFDUVJhkKQHnu9sfdifgZUYC_2BEeMB7jUNnTY5n0TbvKIbVS8toVw0Gar7AP3_548Mg'

      messaging.getToken({ vapidKey: firebaseKeyPair }).then((currentToken) => {
        if (currentToken) {
          self.updateUserFcm(currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      }).finally(() => {
        this.doneInitFirebase = true
      });

      messaging.onMessage(function (payload) {
        console.log('Receiving foreground message');
        console.log(payload)
        self.showNotification(payload.notification, payload.data)
      });
    },
    showNotification(notification, data) {
      this.$notify({
        type: 'success',
        title: notification.title,
        text: notification.body,
      });
      if (data) notification.link = data.link
      this.notifications.unshift(notification)
      this.unreadNotifications += 1
      this.getNotifications()
    },
    getNotifications() {
      var self = this
      if (!this.user) return false
      var ref = db.ref('notifications/' + this.user.id)

      ref.limitToLast(10).on('value', snapshot => {
        const data = snapshot.val()
        self.notifications = []
        
        for (var i in data) {
          self.addNotificationFromFirebase(i, data[i])
        }

        self.notifications = self.notifications.sort(function (a, b) {
          return b.timestamp - a.timestamp
        })
      })

      ref.orderByChild('timestamp').on('child_added', snapshot => {
        var date = new Date(snapshot.val().timestamp * 1000)
        var t = new Date()
        t.setSeconds(t.getSeconds() - 10)
        // if (date >= t) {
        //   self.notify(snapshot.val())
        // }
      })
    },
    addNotificationFromFirebase(id, notification) {
      var seen = false
      if (notification.seen) seen = notification.seen
      var obj = {
        id: id,
        user_id: notification.user_id,
        title: notification.title,
        body: notification.body,
        link: notification.link,
        timestamp: notification.timestamp,
        seen: seen
      }
      this.notifications.push(obj)
    },
    getTime(timestamp) {
      return this.$moment.unix(timestamp).fromNow()
    },
    clearNotifications() {
      let ref = db.ref('notifications/' + this.user.id)
      ref.remove();
    },
    updateUserFcm(token) {
      // console.log("FCM TOKEN "+token)
      User.updateFcm({device_key: token })
        .then(result => {
          
        })
        .catch(() => {
        })
    }
  }
}

</script>
<style>
.unseen {
  background: #a2a2a3;
    color: #fff !important;
}
</style>
