export const UserStatus = ['PENDING','ACTIVE','INACTIVE']
  
export const BUSINESS_TYPES = [
  'Sole proprietorship',
  'General partnership',
  'Limited partnership',
  'Limited liability partnership (LLP)',
  'C corporation',
  'S corporation',
  'Benefit corporation',
  'Limited liability company (LLC)',
  'Nonprofit',
  'Joint venture'
]