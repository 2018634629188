import Vue from 'vue'
import Vuex from 'vuex'

import { LocalStorageService } from '@/services/LocalStorageService'
import {
  UserStatus
} from '@/Constants'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization' : 'Bearer '+LocalStorageService.getToken(),
    },
    user: LocalStorageService.getUser(),
    promo_types: ['setup_fee', 'plan', 'mail', 'meeting_room', 'desk'],
    discount_types: [
      { value: 0, label: 'Fixed Amount' },
      {value: 1, label: 'Percentage %'}
    ],
    token: LocalStorageService.getToken(),
    userStatus: UserStatus,
    formDataHeaders: {
      'Content-Type': 'multipart/form-data',
      'Authorization' : 'Bearer '+LocalStorageService.getToken(),
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Origin": "*"
    },
  },
  mutations: {
    saveToken: (state, token) => {
      LocalStorageService.setToken(token)
      state.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer '+token
      };
    },
    saveUser: (state, user) => {
      LocalStorageService.setUser(user)
      state.user = user;
    },
  }
})